const ENDPOINT = {
  BEST_SELLING_SHOPS: '/creator-admin-backend/api/v1/highlights/shops-bestselling',
  S3_BUCKET: 'https://jq-prod-shared-product-images.s3-eu-central-1.amazonaws.com/juniqe-videos',
};

const UNIQUE_IDENTIFIER = { CONFIGURATOR_COLLAGE: '/configurator/' };

export {
  ENDPOINT,
  UNIQUE_IDENTIFIER,
};
